import { AppModule, VuexModuleConstructor } from '@/common/types'
import CustomerStore from './CustomerStore'
import CustomerModule from './CustomerModule'

export class Customers extends AppModule<CustomerStore, CustomerModule> {
  public get name(): string {
    return 'customers'
  }

  protected get storeClass(): VuexModuleConstructor<CustomerStore> {
    return CustomerStore
  }
}

export default CustomerModule
