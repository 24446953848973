
import { Common } from '@/common'
import { Access } from '@/common/enums'
import { System } from '@/modules/system'
import $app from '@/plugins/modules'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Customers } from '..'
import { CustomerLinks, IPatient } from '../types'

@Component
export default class CustomerEdit extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  customers = $app.module(Customers);

  @Prop(String)
  readonly EntityId: string | undefined;

  loading = false;
  storing = false;
  showChilds = false;
  showDuplicateWarn = false;

  tab: string = 'tabGeneral';
  patientLocal: IPatient = {
    patientId: 0,
    patientParent: null,
    patientVIP: 0,
    patientFirstName: '',
    patientLastName: '',
    patientPhone: '',
    patientCode: '',
    patientGroups: '',
    patientBirthday: null,
    languageCode: 'lv',
    lastVisit: new Date(),
    patientDoctorName: '',
    photo: null,
    patientAttrator: null,
    state: 0,
    selectedPlan: 0,
    curingVisit: 0,
    patientSMS: true,
    photos: [],
    teeth: [],
    plans: [],
    visits: [],
    childs: [],
    researches: [],
    audit: [],
    processing: false
  };

  get actions() {
    return this.tab === 'tabGeneral' || this.tab === 'tabOrto'
      ? [{ code: 'save', caption: 'system.Save', icon: 'fas fa-save', color: 'primary', disabled: this.storing, callback: this.store }]
      : [];
  }

  get caption(): string {
    return this.patient.patientFirstName
      ? $app.i18n('dplus.reports.PatientName') + ': ' + this.patient.patientLastName + ' ' + this.patient.patientFirstName
      : $app.i18n('dplus.customers.PatientLoading');
  }

  get patient(): IPatient {
    const id: number = +(this.EntityId || '0');

    if (this.patientLocal.patientId !== id) {
      this.loading = true;
      this.load(id).then((item) => {
        this.patientLocal = item;
        this.loading = false;
      });
    }

    return this.patientLocal;
  }

  get patientColor(): string {
    const item = $app.item('PatientStateGlossary', this.patient.state);
    return item ? item.color : '';
  }

  get hasAuditAccess(): boolean {
    return $app.access(Access.SecurityManager)
  }

  selectChild(patient: IPatient) {
    this.showChilds = false;
    this.$router.push({ name: CustomerLinks.CustomerEdit, params: { EntityId: '' + patient.patientId } });
  }

  async removeChild(patient: IPatient) {
    this.patient.processing = true;
    try {
      await $app.delete('/api/patients/' + this.patient.patientId + '/childs/' + patient.patientId);
      const index = this.patient.childs.findIndex(r => r.patientId === patient.patientId);
      if (index >= 0) {
        this.patient.childs.splice(index, 1);
      }
      this.showChilds = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.patient.processing = false;
  }

  openChilds() {
    if (this.patient.patientParent) {
      this.$router.push({ name: CustomerLinks.CustomerEdit, params: { EntityId: '' + this.patient.patientParent } });
    } else {
      this.showChilds = true
    }
  }

  async load(id: number): Promise<IPatient> {
    try {
      const result: IPatient = await $app.get('/api/patients/' + id);

      if (this.hasAuditAccess) {
        result.audit = await await $app.get('/api/admin/users/audit/patient/' + id);
      }

      return result;
    } catch (err) {
      $app.pushError(err);
      throw err;
    }
  }

  async store() {
    this.storing = true;
    try {
      const sameCustomers = await this.customers.$store.findPatientsByPhone(this.patientLocal.patientPhone);
      if (sameCustomers && sameCustomers.filter(cust => cust.patientId !== this.patientLocal.patientId).length > 0) {
        this.showDuplicateWarn = true;
      } else {
        this.patientLocal = await $app.post('/api/patients', this.patientLocal);
      }
    } catch (err) {
      $app.pushError(err);
    }
    this.storing = false;
  }

  async storeDuplicate() {
    this.showDuplicateWarn = false;
    this.storing = true;
    try {
      this.patientLocal = await $app.post('/api/patients', this.patientLocal);
    } catch (err) {
      $app.pushError(err);
    }
    this.storing = false;
  }
}
